.custom_dot {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  height: 10px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 2px;
}

.custom_dot li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
}

.custom_dot li button {
  border: none;
  background: #e0e0e0;
  color: transparent;
  display: inline-block;
  height: 10px;
  width: 10px;
  padding: 5px;
  border-radius: 5px;
}

.custom_dot li.slick-active button {
  background-color: #503fec;
}

.custom_dot li button:hover,
.custom_dot li button:focus {
  outline: 1;
}
